.follow-info-panel.false {
    cursor: pointer;
    z-index: 900;
    border-radius: 25px;
    background-color: rgba(59, 59, 59, 0.5);
    margin: 5px 0 0 5px; 
    height: 90px;
    width: 300px;
    text-align: center;
    position: absolute;
}

.follow-info-panel.true {
    cursor: pointer;
    z-index: 900;
    border-radius: 25px;
    background-color: rgba(59, 59, 59, 0.5);
    margin: 5px 0 0 5px; 
    height: 90px;
    width: 300px;
    text-align: center;
    position: absolute;
    margin-top: 140px;
}

.icon-bg {
    color: white;
}

.follow-icon {
    font-size: 38px !important;
}

.follow-title {
    color: white
}