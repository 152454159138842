/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  padding: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding: 2px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.column {
  border-right: 1px solid lightgrey;
  padding-top: 8px;
  box-sizing: border-box;
}

.day-label {
  font-size: 12px;
  color: rgb(0,159,218);
}

.hour-label {
  display: block;
  font-size: 7px;
  color: grey;
  width: 9.04px;
  text-align: center;
}

.hours-cont {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 2px;
}

.hours-cont:first-of-type {
  margin-top: 10px;
}