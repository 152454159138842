.calendar {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.track-events {
  border-bottom: 1px solid lightgrey;
  position: absolute;
  top: 50px;
  margin-top: 14px;
  overflow-y: auto;
}

.train-events {
  position: relative;
}

.name_cont {
  width: 150px;
  text-align: right;
  padding-right: 5px;
  font-size: 14px;
}

.event_cont {
  border-bottom: 1px solid lightgrey;
  position: relative;
}

.divider {
  border-bottom: 5px solid rgb(0, 159, 218);
  position: relative;
}
