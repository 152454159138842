.fallback-main{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.reload-btn {
    margin-top: 8px !important;
    width: 100px;
    background-color: rgb(0, 154, 225);;
}