.event-hover {
    color: #c73f00;
    background-color: #c73f00;
    opacity: 0.5;
  }
  
  .event-hover.stop.false {
    color: #c73f00;
    background-color: #c73f00;
  }
  
  .event-hover.restricted.false {
    color: #ffc300;
    background-color: #ffc300;
  }
  
  .event-hover.stop.true {
    color: #c73f00;
    background-color: #c73f00;
    opacity: 0.3;
  }
  
  .event-hover.restricted.true {
    color: #ffc300;
    background-color: #ffc300;
    opacity: 0.3;
  }
  
  .event-hover.trackcapacityreservation.false {
    color: #59a863;
    background-color: #59a863;
  }
  
  .event-hover.trackcapacityreservation.true {
    color: #59a863;
    background-color: #59a863;
    opacity: 0.3;
  }
  
  .event-hover.servicelocationreservation.false {
    color: #b340ff;
    background-color: #b340ff;
  }
  
  .event-hover.servicelocationreservation.true {
    color: #b340ff;
    background-color: #b340ff;
    opacity: 0.3;
  }
  
  .event-hover.alarm.false {
    color: #ff0000;
    background-color: #ff0000;
  }
  
  .event-hover.alarm.true {
    color: #ff0000;
    background-color: #ff0000;
    opacity: 0.3;
  }
  
  .event-hover.other.false {
    color: #959595;
    background-color: #959595;
  }
  
  .event-hover.other.true {
    color: #959595;
    background-color: #959595;
    opacity: 0.3;
  }