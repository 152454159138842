.prox-map {
    width: 100%;
    height: 90%;
    position: fixed;
    z-index: 1;
}

@media only screen and (max-height: 768px) {
    .prox-map {
        height: 70%;
    }
}


@media only screen and (max-height: 768px) {
    .prox-map {
        height: 70%;
    }
}

@media only screen and (max-height: 900px) {
    .prox-map {
        height: 80%;
    }
}

@media only screen and (max-height: 1080px) {
    .prox-map {
        height: 85%;
    }
}