.event-cont {
  border-bottom: 1px solid lightgrey;
  padding: 2px 0;
  width: 100%;
  position: relative;
}

.event {
  background-color: #c73f00;
  cursor: pointer;
  height: calc(100% - 4px);
  position: absolute;
  box-sizing: border-box;
  border-right: 1px solid #fff;
  opacity: 0.5;
}

/* BASES */

.event.stop {
  background-color: #c73f00;
}

.event.restricted {
  background-color: #ffc300;
}

.event.trackcapacityreservation {
  background-color: #59a863;
}

.event.servicelocationreservation {
  background-color: #b340ff;
}

.event.alarm {
  background-color: #ff0000;
}

.event.other {
  background-color: #959595;
}

/* STARTED */

.event.stop.false.started {
  border-style: dashed;
}

.event.restricted.false.started {
  border-style: dashed;
}

.event.stop.true.started {
  opacity: 0.3;
  border-style: dashed;
}

.event.restricted.true.started {
  opacity: 0.3;
  border-style: dashed;
}

.event.trackcapacityreservation.false.started {
  border-style: dashed;
}

.event.trackcapacityreservation.true.started {
  opacity: 0.3;
  border-style: dashed;
}

.event.servicelocationreservation.false.started {
  border-style: dashed;
}

.event.servicelocationreservation.true.started {
  opacity: 0.3;
  border-style: dashed;
}

.event.alarm.false.started {
  border-style: dashed;
}

.event.alarm.true.started {
  opacity: 0.3;
  border-style: dashed;
}

.event.other.false.started {
  border-style: dashed;
}

.event.other.true.started {
  opacity: 0.3;
  border-style: dashed;
}

/* FINISHED */

.event.stop.false.finished {
  border-style: solid;
}

.event.restricted.false.finished {
  border-style: solid;
}

.event.stop.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.event.restricted.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.event.trackcapacityreservation.false.finished {
  border-style: solid;
}

.event.trackcapacityreservation.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.event.servicelocationreservation.false.finished {
  border-style: solid;
}

.event.servicelocationreservation.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.event.alarm.false.finished {
  border-style: solid;
}

.event.alarm.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.event.other.false.finished {
  border-style: solid;
}

.event.other.true.finished {
  opacity: 0.3;
  border-style: solid;
}

.track {
  position: absolute;
  /* margin-top: 220px; */
  /* left: 41702px; */
  width: 150px;
}