.header {
  background-color: rgb(0,159,218);
  height: 24px;
  line-height: 24px;
  width: 100%;
}

.header-title {
  color: #fff;
  font-size: 16px;
  margin: 0 0 0 auto;
}

.fullscreen-toggler {
  flex: 0;
  margin: 0 5px 0 auto;
  cursor: pointer;
}

.fullscreen-icon {
  color: #fff;
  font-size: 18px;
  /* PRX-150 - Hidden for now */
  /* opacity: 0   */
}

.today-toggler {
  border: 1px solid rgb(0, 159, 218);
  cursor: pointer;
  padding: 2px;
  background-color: rgb(0, 159, 218);
  border-radius: 3px;
  flex: 0;
  margin-left: 0;
}
@media only screen and (min-width: 900px) {
  .today-toggler {
    margin-left: 10px;
  }
}

.today-icon {
  color: #fff;
  font-size: 18px;
}

.view-toggler-cont {
  flex: 0;
  border: 1px solid rgb(187, 188, 190);
  border-radius: 2px;
  /* PRX-149 - Hidden for now */
  opacity: 0;
}

.view-toggler {
  padding: 2px 4px;
  cursor: pointer;
}

.view-toggler:first-child {
  border-right: 1px solid lightgrey;
}

.view-toggler.selected {
  background-color: rgba(0, 150, 255, 0.1);
}