.filter-info-panel {
    z-index: 900;
    border-radius: 25px;
    background-color: rgba(59, 59, 59, 0.5);
    margin: 5px 0 0 5px; 
    height: 130px;
    width: 300px;
    text-align: center;
    position: absolute;
}

.icon-bg {
    color: white;
}

.filter-icon {
    color: red;
    font-size: 40px !important;
}

.filter-title {
    color: white
}