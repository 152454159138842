.default-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: relative;
}

.default-map {
    height: 90%;
}

.timeline {
    height: 10%;
    overflow-y: scroll;
    position: sticky;
    bottom: 0px;
}

@media only screen and (max-height: 768px) {
    .timeline {
        height: 30%;
    }

    .default-map {
        height: 70%;
    }
}

@media only screen and (max-height: 768px) {
    .timeline {
        height: 30%;
    }

    .default-map {
        height: 70%;
    }
}

@media only screen and (max-height: 768px) {
    .timeline {
        height: 30%;
    }

    .default-map {
        height: 70%;
    }
}

@media only screen and (max-height: 900px) {
    .timeline {
        height: 20%;
    }

    .default-map {
        height: 80%;
    }
}

@media only screen and (max-height: 1080px) {
    .timeline {
        height: 15%;
    }

    .default-map {
        height: 85%;
    }
}
