.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: relative;
}

.map {
    /* border: 2px solid blue; */
    height: 50%;
}

.scheduler {
    height: 50% !important;
    width: calc(100% - 110px);
    position: sticky;
}