.columnSmallLeft {
    display: table;
    float: left;
    width: 9%;
    height: 76px;
}

.columnLargeRight{
    display: table;
    float: right;
    width: 90%;
}

.row {
    display: table;
    width: 100%;
}

.rowSmall {
    display: table;
    width: 100%;
}


.trainIconStyleLeft {
    font-size: 28px !important;
    margin-top: 22px;
    margin-bottom: 10px;
    margin-left: 4px;
    color: black;
    background-color: #fff;
}

.textGrey {
    /* ...theme.typography, */
    font-size: 12px !important;
    font-weight: 40;
    margin-top: 4px !important;
    margin-left: 2px !important;
    margin-bottom: 8px !important;
    color: rgba(0, 0, 0, 0.5);
}

.targetTextTitle1 {
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-bottom: 4px !important;
}

.targetTextTitle2 {
    font-size: 12px !important;
    font-weight: 400 !important;
    margin-bottom: -4px !important;
    margin-top: 6px !important;
}