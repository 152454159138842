.main {
    display: flex;
    width: 100vw;
    overflow: hidden;
    z-index: 100;
}

.navigation {
    /* border: 2px solid red; */
    display: grid;
    width: 110px;
    min-width: 110px;
    background-color: white;
    border: 1px solid  rgb(96, 96, 96);
    height: 100vh;
}

.logo {
    width: 50px;
    margin-left: 30px;
    margin-top: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: relative;
}