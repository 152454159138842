.event-cont {
  width: 100%;
  border-bottom: 1px solid lightgrey;
  position: relative;
}

.train-event {
  cursor: pointer;
  height: calc(100% - 4px);
  position: absolute;
  box-sizing: border-box;
  opacity: 0.7
}


.train-icon-content {
  position: absolute;
  max-width: fit-content;
}

.train-event-tooltip {
  font-size: 18px;
}

.train-event-diff {
  color: #fff;
  margin: 0 5px;
  border-radius: 10px;
  padding: 1px 5px;
  font-size: 18px;
}